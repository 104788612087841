<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-card header="Playlist informatie" class="mt-4">

        <field
          v-if="release.Tracks.length > 1"
          name="Attributes.ReleaseType"
          :defs="fieldDefs"
          :model="release"
          :errors="errors" />
        <page-snippet name="album-intro" class="mt-5" />

        <field 
          v-if="release.Tracks.length > 1"
          :defs="fieldDefs"
          name="TitleLanguage"
          @change="updateAttribution"
          :model="release"
          :errors="errors" />

        <field 
          v-if="release.Tracks.length > 1"
          :defs="fieldDefs"
          name="Title"
          :model="release"
          :errors="errors" />

        <field 
          v-if="release.Tracks.length > 1"
          :defs="fieldDefs"
          name="VersionTitle"
          :model="release"
          :errors="errors" />

        <contributors
          v-if="release.Tracks.length > 1"
          class="mt-5"
          :copyToTracks="false"
          :defs="fieldDefs"
          @change="updateAttribution"
          type="album"
          :model="release"
          :validationErrors="errors" />

        <field 
          :defs="fieldDefs"
          name="UPCorEAN"
          :model="release"
          :errors="errors" />

        <field 
          :defs="fieldDefs"
          name="ArtistURL"
          :model="release"
          :errors="errors" />

        <field 
          :defs="fieldDefs"
          name="CatalogNr"
          :model="release"
          :errors="errors" />

        <field 
          v-if="release.Tracks.length > 1"
          :defs="fieldDefs"
          name="VolumeNr"
          :model="release"
          :errors="errors" />

        <field 
          :defs="fieldDefs"
          name="OriginCountry"
          :model="release"
          :errors="errors" />

        <field 
          :defs="fieldDefs"
          name="Label"
          :readonly="true"
          :model="release"
          :errors="errors" />

        <field 
          :defs="fieldDefs"
          name="LabelURL"
          :model="release"
          :errors="errors" />

        <field 
          v-if="release.Tracks.length > 1"
          :defs="fieldDefs"
          name="PLine"
          :model="release"
          :errors="errors" />

        <field 
          v-if="release.Tracks.length > 1"
          :defs="fieldDefs"
          name="CLine"
          :model="release"
          :errors="errors" />

        <field 
          :defs="fieldDefs"
          name="OriginalReleaseFormat"
          :model="release"
          :errors="errors" />

        <field 
          :defs="fieldDefs"
          name="OriginalReleaseDate"
          :date="true"
          :model="release"
          :errors="errors" />

        <field 
          :defs="fieldDefs"
          name="ReleaseDate"
          :date="true"
          :model="release"
          :errors="errors" />

        <b-alert :show="releaseDateDaysOffset < 5 && releaseDateDaysOffset >= 0" variant="warning" v-html="$root.$data.pageSnippets['releasedate-too-soon']">
        </b-alert>

        <field 
          :defs="fieldDefs"
          name="Attributes.PreOrderDays"
          :model="release"
          :errors="errors" />

        <field 
          v-if="getAttribute(release, 'PreOrderDays')"
          :defs="fieldDefs"
          name="Attributes.CoverArtPreviewDays"
          :model="release"
          :errors="errors" />

        <field 
          v-if="getAttribute(release, 'PreOrderDays')"
          :defs="fieldDefs"
          name="Attributes.TrackListingPreviewDays"
          :model="release"
          :errors="errors" />

        <field 
          v-if="getAttribute(release, 'PreOrderDays')"
          :defs="fieldDefs"
          name="Attributes.PreOrderPreViews"
          :model="release"
          :errors="errors" />

        <field 
          v-if="release.Tracks.length > 1"
          :defs="fieldDefs"
          name="Tags.TopLevel"
          :model="release"
          :errors="errors" />

        <field 
          v-if="release.Tracks.length > 1"
          :defs="fieldDefs"
          name="Tags.RecordingVersion"
          :model="release"
          :errors="errors" />

        <field 
          v-if="release.Tracks.length > 1"
          :defs="fieldDefs"
          name="Tags.MainGenre"
          @change="setTag(release, 'SubGenre', null)"
          :model="release"
          :errors="errors" />

        <field 
          v-if="release.Tracks.length > 1"
          :defs="fieldDefs"
          name="Tags.SubGenre"
          :model="release"
          :errors="errors" />

        <field 
          v-if="release.Tracks.length > 1"
          :defs="fieldDefs"
          name="Tags.Occasion"
          :model="release"
          :errors="errors" />

        <field 
          v-if="['Album', 'Compilation'].includes(getAttribute(release, 'ReleaseType'))"
          :defs="fieldDefs"
          name="Tags.AlbumPriceTier"
          :model="release"
          :errors="errors" />

        <!--
        <page-snippet name="mfit" class="mt-5" />

        <field 
          :defs="fieldDefs"
          name="Attributes.MFIT Available"
          :model="release"
          :errors="errors" />

        <field 
          :defs="fieldDefs"
          name="Attributes.MFIT Engineer"
          :model="release"
          :errors="errors" />

        <field 
          :defs="fieldDefs"
          name="Attributes.MFIT Engineer Identifier"
          :model="release"
          :errors="errors" /> -->

        <page-snippet name="promotion" class="mt-5" />

        <field 
          :defs="fieldDefs"
          name="Attributes.Promotion"
          :model="release"
          :errors="errors" />

        <field 
          v-if="getAttribute(release, 'Promotion') == 'True'"
          :defs="fieldDefs"
          name="Attributes.MarketingDrivers"
          :model="release"
          :errors="errors" />

        <field 
          v-if="getAttribute(release, 'Promotion') == 'True'"
          :defs="fieldDefs"
          name="Attributes.Promotional Activities"
          :model="release"
          :errors="errors" />

        <field 
          v-if="getAttribute(release, 'Promotion') == 'True'"
          :defs="fieldDefs"
          name="Attributes.MarketingSynopsis"
          :model="release"
          :errors="errors" />

        <field 
          v-if="getAttribute(release, 'Promotion') == 'True'"
          :defs="fieldDefs"
          name="Attributes.TourDetails"
          :model="release"
          :errors="errors" />

        <field 
          v-if="getAttribute(release, 'Promotion') == 'True'"
          :defs="fieldDefs"
          name="Attributes.Instagram"
          :model="release"
          :errors="errors" />

        <field 
          v-if="getAttribute(release, 'Promotion') == 'True'"
          :defs="fieldDefs"
          name="Attributes.Facebook"
          :model="release"
          :errors="errors" />

        <field 
          v-if="getAttribute(release, 'Promotion') == 'True'"
          :defs="fieldDefs"
          name="Attributes.YouTube"
          :model="release"
          :errors="errors" />

        <field 
          v-if="getAttribute(release, 'Promotion') == 'True'"
          :defs="fieldDefs"
          name="Attributes.MarketingComment"
          :model="release"
          :errors="errors" />

        </b-card>

        <b-alert v-if="Object.keys(errors).length" show variant="danger" class="mt-4">
          De playlist informatie bevat nog fouten. Scroll naar boven om deze te corrigeren.
        </b-alert>

        <b-button :disabled="!readyToProceed" @click="proceed" class="mt-4">Ga verder met de volgende stap</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import ReleaseConceptHelper from '@/mixins/ReleaseConceptHelper'
  import moment from 'moment'
  import { generateAttribution } from '@/functions'

  export default {
    name: 'Release',
    components: {
      'field': () => import('@/components/ReleaseConceptField'),
      'page-snippet': () => import('@/components/PageSnippet'),
      'contributors': () => import('@/components/ReleaseConceptContributors'), 
    },
    mixins: [
      ReleaseConceptHelper
    ],
    data () {
      return {
        errors: {},
        fieldDefs: this.$root.$data.fieldDefs.Album,
      }
    },
    props: {
      release: Object,
    },
    watch: {
      
    },
    methods: {
      updateAttribution () {
        this.release.Attribution = generateAttribution(this.release, true)
      },
      proceed () {
        let defs = this.fieldDefs
        let errors = {}

        this.validate(this.release, defs, 'Attributes.ReleaseType', errors)
        this.validate(this.release, defs, 'TitleLanguage', errors)
        this.validate(this.release, defs, 'Title', errors)
        this.validate(this.release, defs, 'Attribution', errors)
        this.validate(this.release, defs, 'UPCorEAN', errors)
        this.validate(this.release, defs, 'CatalogNr', errors)
        this.validate(this.release, defs, 'VolumeNr', errors)
        this.validate(this.release, defs, 'PLine', errors)
        this.validate(this.release, defs, 'CLine', errors)
        this.validate(this.release, defs, 'OriginalReleaseFormat', errors)
        this.validate(this.release, defs, 'OriginalReleaseDate', errors)
        this.validate(this.release, defs, 'ReleaseDate', errors)
        if (['Album', 'Compilation'].includes(this.getAttribute(this.release, 'ReleaseType')))
          this.validate(this.release, defs, 'Tags.AlbumPriceTier', errors)
        this.validate(this.release, defs, 'Tags.MainGenre', errors)
        this.validate(this.release, defs, 'Attributes.Promotion', errors)
        this.validate(this.release, defs, 'Attributes.PreOrderDays', errors)
        this.validate(this.release, defs, 'Attributes.CoverArtPreviewDays', errors)
        this.validate(this.release, defs, 'Attributes.TrackListingPreviewDays', errors)
        this.validateContributors(this.release, errors)

        this.$set(this, 'errors', errors)
        console.log(Object.keys(errors))

        if (Object.keys(errors).length == 0)
          this.$emit('proceed')
      },
    },
    computed: {
      releaseDateDaysOffset () {
        let date = this.release.ReleaseDate
        if (!date) return null
        return -moment().startOf('day').diff(date, 'days')
      },
      readyToProceed () {
        return true
      }
    },
    mounted () {

    }
  }
</script>

<style lang="scss" scoped>

</style>